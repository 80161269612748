import * as Sentry from '@sentry/nextjs';
const beforeSend = (
  event: Sentry.ErrorEvent,
  hint: Sentry.EventHint & {
    originalException?: {
      message?: string | Error | null;
    } | null;
  },
): Sentry.ErrorEvent | PromiseLike<Sentry.ErrorEvent> => {
  const message = (hint?.originalException?.message as string) ?? '';
  const requestFailed4 = /Request failed with status code 4/i.exec(
    message,
  )?.[0];
  const requestFailed5 = /Request failed with status code 5/i.exec(
    message,
  )?.[0];
  const networkError = message === 'Network Error';
  const isNonErrorException =
    event?.exception?.values?.[0]?.value?.startsWith(
      'Non-Error exception captured',
    ) || message?.startsWith('Non-Error exception captured');
  const timeoutErrorNetwork = message === 'timeout of 20000ms exceeded';
  const networkRequestFailed = message === 'Network request failed';
  const internetConnectionFailed = message.includes(
    'Check Internet connection',
  );
  const firebaseIsNotRegistered = message.includes(
    'Firebase Installation is not registered',
  );
  const firebaseAppOffline = message.includes('Application offline');
  if (
    requestFailed4 ||
    requestFailed5 ||
    isNonErrorException ||
    networkError ||
    timeoutErrorNetwork ||
    networkRequestFailed ||
    internetConnectionFailed ||
    firebaseIsNotRegistered ||
    firebaseAppOffline
  ) {
    return null;
  }
  return event;
};
export default beforeSend;
